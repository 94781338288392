.ProjectCard {
    position: relative;
    width: 500px;
    margin: 0 0 30px;
    transition: transform 0.5s ease;
}

.ProjectCard>.ProjectContainer {
    border-radius: 10px;
    box-shadow: 3px 3px 3px;
    background-color: rgba(0, 150, 255, 1);
    padding: 0 5px 10px 5px;
}

@font-face {
    font-family: "project-header-font";
    src: url(../../../Assets/Fonts/initial-header-font.ttf);
}

@font-face {
    font-family: "project-description-font";
    src: url(../../../Assets/Fonts/random-content-font.ttf);
}

.ProjectContainer> h3 {
    font-family: "project-header-font";
}

.ProjectContainer>p {
    font-family: "project-description-font";
}

.ProjectContainer> a {
   float: right;
   margin-right: 10px;
   color: white;
}

.ProjectContainer> a:hover {
    color: black;
    transition: all 0.7s ease;
}

.ProjectCard:hover {
    transform: scale(1.1);
}

.ProjectCard>.ProjectContainer>.ProjectImage {
    width: 450px;
    border-radius: 10px;
    margin-top: 10px;
}

.ProjectCard>.ProjectContainer>.TagsContainer>img {
    width: 35px;
}

@media (max-width: 1300px) {

    .ProjectCard {
        width: 50%;
        margin-left: 80px;
    }

    .ProjectCard>.ProjectContainer>.ProjectImage {
        width: 90%;
    }
}