html,
body,
#root {
    height: 100vh;
    text-align: center;
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

@media (max-width: 767px) {

    html,
    body,
    #root {
        overflow-x: hidden;
    }
}