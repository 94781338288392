.ProjectList>h2 {
    color: white;
    font-style: oblique;
    text-align: left;
    margin-top: 1000px;
}

@media (max-width: 767px) {

    .ProjectList>h2 {
        text-align: center;
        margin-right: 130px;
        margin-top: 250px;
    }
}

@media (min-width: 767px) and (max-width: 1300px) {
    .ProjectList {
        margin-left: 15%;
    }

    .ProjectList>h2 {
        text-align: center;
        margin-right: 180px;
        margin-top: 150px;
    }
}