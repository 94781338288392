@font-face {
    font-family: "initial-header-font";
    src: url(../../../Assets/Fonts/initial-header-font.ttf);
}

@font-face {
    font-family: "random-content-font";
    src: url(../../../Assets/Fonts/random-content-font.ttf);
}

.Header {
    white-space: nowrap;
    text-align: left;
    padding-left: 350px;
    padding-top: 30px;
}

@media (max-width: 575px) {
    .Header {
        white-space: initial;
        padding: 20px 150px 0 20px;
    }
    .HeaderImageContainer>img {
        width: 100%;
        height: auto;
    }
}

@media (min-width: 575px) and (max-width: 1300px) {
    .Header {
        white-space: initial;
        padding-left: 20px;
    }
    .HeaderImageContainer>img {
        width: 90%;
        height: auto;
    }
}

.Header>.ContentWrapper {
    height: 180px;
}

.Header>.ContentWrapper>.RandomContent>h1 {
    color: blue;
    font-family: "random-content-font";
}

.Header>.ContentWrapper>.InitialContent>h1 {
    color: white;
    font-style: oblique;
    font-family: "initial-header-font";
}

@keyframes headerAnimation {
    from {
        opacity: 0.25;
        transform: translateX(-100%) scale(0.75);
    }

    to {
        opacity: 1;
        transform: translateX(0) scale(1);
    }
}

.randomContentAnimation {
    animation: headerAnimation 1.5s ease;
}