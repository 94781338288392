.Contact>.SocialLinks>a {
    margin: 0 20px;
    text-decoration: none;
    font-size: large;
    transition: all 0.4s ease;
}

.Contact>.SocialLinks>a:hover {
    color: lightblue;
}

@media (max-width: 500px) {
    .Contact {
        margin-right: 100px;
    }
}

@media (max-width: 767px) {

    .Contact>.SocialLinks>a {
        display: block;
    }
}

@media (min-width: 767px) and (max-width: 1300px) {

    .Contact>.SocialLinks>a {
        font-size: small;
    }
}