.Resume {
    display: flex;
    flex-direction: column;
    margin: 0 -10px;
}

.Resume .ResumeButton {
    transform: rotate(-90deg);
    background-color: white;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    transition: all 0.5s ease;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.Resume .ResumeButton:hover {
    background-color: green;
    transform: rotate(-90deg) scale(1.2);
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.3);
    text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.3);
}


.DownloadIcon {
    transform: rotate(90deg);
}