.About>.carousel {
    min-width: 300px;
    max-width: 550px;
    margin-bottom: 30px;
}

.About>h2 {
    text-align: left;
    margin-bottom: 30px;
    color: white;
    font-style: oblique;
}

@media (max-width: 767px) {

    .About {
        display: grid;
        justify-content: center;
        margin-left: 75px;
    }

    .About>.carousel {
        max-width: 300px;
        margin-right: 120px;
    }

    .About>h2 {
        margin-left: 45px;
    }
}

@media (min-width: 767px) and (max-width: 1300px) {

    .About {
        display: grid;
        justify-content: center;
    }

    .About>h2 {
        margin-left: 50px;
    }
}