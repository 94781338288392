.Menu {
    font-size: large;
    font-weight: bold;
}

.Menu>.ScrollToTopContainer {
    width: 60px;
    border-radius: 20px;
    color: white;
    background-color: rgba(0, 0, 255, 0.4);
    position: fixed;
    bottom: 5%;
    right: 5%;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.Menu>.ScrollToTopContainer.visible {
    opacity: 1;
}

.nav-link {
    color: white;
}

.nav-link:hover {
    color: blue;
}

.Menu>.navbar>.container-fluid>.collapse>.navbar-nav>.nav-item>.active {
    background-color: blue;
    border-radius: 10px;
}

.Menu>.navbar>.container-fluid>.navbar-toggler {
    background-color: rgba(0, 0, 255, 0.75);
}

@media (max-width: 485px) {
    .Menu>.navbar>.container-fluid>.navbar-collapse {
        margin-right: 100px;
    }
}

@media (max-width: 575px) {

    .Menu>.navbar>.container-fluid>.navbar-collapse {
        background-color: rgba(0, 0, 100, 1);
        border-radius: 10px;
        margin-top: 10px;
    }
}