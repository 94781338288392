.Layout {
    height: 100vh;
    display: grid;
    grid-template-rows: 10% auto 5%;
    grid-template-columns: repeat(20, 1fr);
}

.Layout>nav,
footer {
    grid-column: span 20;
}

.Layout>header {
    grid-column: span 5;
}

.Layout>main {
    grid-column: span 15;
}

.Layout>aside {
    grid-column: span 1;
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.fadeIn {
    animation: fadeIn 1.25s ease-in;
}

@keyframes fadeIn {
    from {
        opacity: 0.25;
        transform: scale(0.75);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

@media (max-width: 1300px) {

    .Layout {
        grid-template-rows: 10% auto auto auto auto;
        grid-template-columns: 1fr;
    }

    .Layout>nav,
    .Layout>footer,
    .Layout>header,
    .Layout>aside,
    .Layout>main {
        grid-column: span 1;
    }
}