.Footer {
    margin-top: 10px;
}

.Footer>p {
    color: gray;
}

@media (max-width: 500px) {
    .Footer {
        margin-left: -80px;
    }
}

@media (max-width: 767px) {

    .Footer {
        display: flex;
        justify-content: center;
        margin-right: 20px;
        padding-top: 10px;
        font-size: small;
    }
}